// 우선순위를 각 칸마다 임의로 부여
// 해당하는 항목에 대해 가장 큰거를 제일 큰값으로 부여함
// 점수범위: int: -10(0) ~ 10(0) (점수의 간격 크게 부여:정밀성을 위해)
// add_score에 있는 배열 다 score에 하나씩

// 번호(index) : ~
// 점수 차등: 10 9 8 6 4 2 0 -2 -4 -6 -8 -10

// 질문에 해당되지 않는것들끼리의 점수차이: -1,-2~ 이런식..? -> 이렇게 크면안됨
// 범위를 크게하는 대신에 점수를 정밀하게 측정해야함... --> 점수 편향될 가능성 큼. -10~10까지해보고 점수범위 상황봐서 변경하기 ㅇㅇ

export const QuestionData = [
  {
    q: "Q1. 나는 웹소설의 전개가 빠를수록 좋다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [-4, 4, 10, 2, -6, -8, 9, 0, -10, 8, -2, 6],
      },
      //(2> 6> 9> 11> 1 > 3> 7> 10> 0> 4> 5> 8)// 12칸 array
      // 10 9  8   6  4   2  0  -2 -4 -6 -8 -10

      {
        answer: "b. 그렇다.",
        add_score: [6, 9, -2, 8, 4, -10, -4, 2, -8, -6, 10, 0],
      },
      //(10> 1> 3> 0> 4> 7> 11> 2> 6> 9> 8> 5)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "c. 보통이다.",
        add_score: [4, 2, -2, 8, 10, -10, -6, 9, -8, -4, 6, 0],
      },
      // (4> 7> 3> 10> 0> 1> 11> 2> 9> 6> 8> 5)
      // 10  9  8  6   4  2  0  -2 -4 -6 -8 -10

      {
        answer: "d. 아니다.",
        add_score: [-2, 0, 4, -6, -10, 10, 8, -8, 9, 6, -4, 2],
      },
      //(5> 8> 6> 9> 2> 11> 1> 0> 10> 3 > 7> 4)
    ], // 10 9  8  6  4  2   0  -2 -4  -6 -8 -10
  },
  {
    q: "Q2. 좋아하는 캐릭터가 생기면 작품이 재미가 없어도 완독한다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [10, 2, 10, -10, 10, -4, -6, 10, -2, 4, 0, -8],
      },
      //(2 =7 =4 =0 >9 >1 >10  >8 >5  >6 >11 >3)
      // 10 10 10  10  4  2   0  -2 -4  -6 -8 -10
      {
        answer: "b. 그렇다.",
        add_score: [2, -8, -10, 0, -2, 10, 6, 4, 10, -6, -4, 10],
      },
      //(8  =5 =11>6>  7> 0>  3>  4> 10> 9> 1> 2)
      // 10 10 10  6  4  2   0  -2 -4  -6 -8 -10

      {
        answer: "c. 보통이다",
        add_score: [0, 10, 6, -4, 4, -6, -10, -2, 2, 10, 10, -8],
      },
      //(1= 9=10 >2 >4 >8  >0  >7 >3  >5 >11 >6)
      //10 10 10  6  4  2   0  -2 -4  -6 -8 -10

      {
        answer: "d. 아니다.",
        add_score: [2, -10, -6, 10, 0, 6, 10, -2, -4, -8, 4, 8],
      },
      // (6=3> 11> 5> 10> 0> 4> 7> 8>  2>  9> 1)
      // 10 10 8   6   4  2  0 -2 -4  -6 -8 -10
    ],
  },
  {
    q: "Q3. 남성 주인공을 좋아하는 여자 캐릭터의 수는 몇 명이 적당할까? ",
    a: [
      {
        answer: "a. 없는 편이 좋다.",
        add_score: [2, -4, -2, 8, 9, -10, 6, 10, -8, 4, 0, -6],
      },
      // (7> 4> 3> 6> 9> 0> 10> 2> 1> 11> 8>5)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "b. 1명",
        add_score: [2, 6, 10, -2, 8, -6, -10, 0, -4, 9, 4, -8],
      },
      // (2> 9> 4> 1> 10> 0> 7> 3> 8> 5> 11> 6)
      // 10  9  8  6  4   2  0  -2 -4 -6 -8 -10

      {
        answer: "c. 2명",
        add_score: [0, -4, -10, 4, -6, 8, 10, 2, 6, -8, -2, 9],
      },
      // (6> 11> 5> 8> 3> 7> 0> 10> 1> 4> 9>2)
      //  10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "d. 많을수록 좋다.",
        add_score: [8, 10, 6, 0, 4, -10, -4, 2, -8, -6, 9, -2],
      },
      // (1> 10> 0> 2> 4> 7> 3> 11> 6> 9> 8 >5)
      //  10  9  8  6  4  2  0  -2 -4 -6 -8 -10
    ],
  },
  {
    q: "Q4. 주인공이 세계관 최강자여야 한다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [2, 0, -4, -6, -8, 8, 9, 4, 10, -10, -2, 6],
      },
      // (8 >6 >5 >11>7 >0> 1> 10> 2> 3> 4  >9)
      //  10 9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer:
          "b. 처음에는 약해도 소설 후반부에는 세계관 최강자가 됐으면 좋겠다.",
        add_score: [8, 0, -8, 6, 4, -4, -10, 10, -2, 2, 9, -6],
      },
      //(7> 10> 0> 3> 4> 9> 1>  8> 5 >11 >2 >6)
      // 10 9   8  6  4  2  0  -2 -4 -6  -8 -10

      {
        answer: "c. 처음에는 약해도 점점 강해지면 좋겠다.",
        add_score: [4, 9, 10, 4, 4, 4, 4, 4, 4, 4, 4, 8],
      },
      // (2>1>11 =0 =8 =5 =9 =4 =3 =7 =10 =4)
      // 10 9 8  4  4  4  4  4   4  4  4  4

      {
        answer: "d. 아니다.",
        add_score: [0, 2, 6, 8, 9, -6, -8, -2, -10, 10, 4, -4],
      },
      // (9> 4 >3 >2 >10>1> 0>  7> 11>5> 6> 8)
      //  10 9  8  6  4  2  0  -2 -4 -6 -8 -10
    ],
  },
  {
    q: "Q5. 가볍게 머리 비우고 볼 수 있는 소설이 좋다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [4, -10, -4, 9, 6, -8, -2, 8, 0, 2, 10, -6],
      },
      // (10> 3> 7> 4> 0> 9> 8> 6> 2> 11> 5 >1)
      //  10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      { answer: "b. 그렇다.", add_score: [1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1] },
      // (1> 5> 10> 0> 6> 11> 8> 7> 3> 9> 2> 4)
      //  10  9   8  6  4  2   0 -2 -4 -6 -8 -10

      {
        answer: "c. 보통이다.",
        add_score: [8, 4, 10, -4, 6, -6, -8, -10, 2, 9, 0, -2],
      },
      // (2> 9> 0> 4> 1> 8> 10> 11> 3> 5> 6> 7)
      //  10 9  8  6  4  2   0  -2 -4 -6 -8 -10
      {
        answer: "d. 아니다.",
        add_score: [2, -10, 0, 4, -4, 6, 10, -2, 9, -6, -8, 8],
      },
      // ( 6> 8> 11> 5> 3> 0>  2> 7>  4> 9> 10>1)
      //  10  9  8   6  4  2   0  -2 -4 -6 -8 -10
    ],
  },
  {
    q: "Q6. 배경이 현대였으면 좋겠다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [-8, -4, -6, 8, -2, 6, 10, 0, 2, -10, 4, 9],
      },
      // (6> 11> 3>  5> 10> 8> 7>  4> 1> 2> 0  >9)
      //  10  9  8   6  4   2  0  -2 -4 -6 -8 -10
      {
        answer: "b. 그렇다.",
        add_score: [9, 6, 8, -6, 4, -4, -10, 2, 0, 10, -2, -8],
      },
      // (9> 0> 2> 1> 4> 7> 8> 10> 5> 3> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "c. 상관없다.",
        add_score: [6, 10, 9, -4, 4, -6, -10, 2, -2, 8, 0, -8],
      },
      // (1> 2> 9> 0> 4> 7> 10> 8> 3> 5> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "d. 현대가 아니었으면 좋겠다.",
        add_score: [6, 8, 10, 4, 0, -6, -10, -8, -2, 9, -4, 2],
      },
      //(2> 9> 1> 0> 3> 11> 4> 8> 10> 5> 7> 6)
      // 10 9  8  6  4  2   0  -2 -4 -6 -8 -10
    ],
  },
  {
    q: "Q7. 소설을 볼 때 주인공이 제일 중요하며, 주인공은 무조건 멋있어야 한다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [-8, -4, -6, 8, -2, 6, 10, 0, 2, -10, 4, 9],
      },
      // (6> 11> 3>  5> 10> 8> 7>  4> 1> 2> 0  >9)
      //  10  9  8   6  4   2  0  -2 -4 -6 -8 -10
      {
        answer: "b. 주인공만큼 주인공의 동료도 활약했으면 좋겠다.",
        add_score: [9, 6, 8, -6, 4, -4, -10, 2, 0, 10, -2, -8],
      },
      // (9> 0> 2> 1> 4> 7> 8> 10> 5> 3> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "c. 주인공보다 주인공의 동료가 더 활약해도 상관없다.",
        add_score: [6, 10, 9, -4, 4, -6, -10, 2, -2, 8, 0, -8],
      },
      // (1> 2> 9> 0> 4> 7> 10> 8> 3> 5> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10
      {
        answer: "d. 아니다.",
        add_score: [6, 8, 10, 4, 0, -6, -10, -8, -2, 9, -4, 2],
      },
    ],
  },
  {
    q: "Q8. 철학적 메시지를 담고 있는 소설이 좋다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [-8, -4, -6, 8, -2, 6, 10, 0, 2, -10, 4, 9],
      },
      // (6> 11> 3>  5> 10> 8> 7>  4> 1> 2> 0  >9)
      //  10  9  8   6  4   2  0  -2 -4 -6 -8 -10
      {
        answer: "b. 상관없다.",
        add_score: [9, 6, 8, -6, 4, -4, -10, 2, 0, 10, -2, -8],
      },
      // (9> 0> 2> 1> 4> 7> 8> 10> 5> 3> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "c. 철학적 메시지가 없는 편이 좋다.",
        add_score: [6, 10, 9, -4, 4, -6, -10, 2, -2, 8, 0, -8],
      },
      // (1> 2> 9> 0> 4> 7> 10> 8> 3> 5> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10
      {
        answer: "d. 아니다.",
        add_score: [6, 8, 10, 4, 0, -6, -10, -8, -2, 9, -4, 2],
      },
    ],
  },
  {
    q: "Q9. 스케일이 큰 소설이 좋다.",
    a: [
      {
        answer: "a. 매우 그렇다.",
        add_score: [-8, -4, -6, 8, -2, 6, 10, 0, 2, -10, 4, 9],
      },
      // (6> 11> 3>  5> 10> 8> 7>  4> 1> 2> 0  >9)
      //  10  9  8   6  4   2  0  -2 -4 -6 -8 -10
      {
        answer: "b. 그렇다.",
        add_score: [9, 6, 8, -6, 4, -4, -10, 2, 0, 10, -2, -8],
      },
      // (9> 0> 2> 1> 4> 7> 8> 10> 5> 3> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "c. 상관없다.",
        add_score: [6, 10, 9, -4, 4, -6, -10, 2, -2, 8, 0, -8],
      },
      // (1> 2> 9> 0> 4> 7> 10> 8> 3> 5> 11> 6)
      // 10  9  8  6  4  2  0  -2 -4 -6 -8 -10

      {
        answer: "d. 아니다.",
        add_score: [6, 8, 10, 4, 0, -6, -10, -8, -2, 9, -4, 2],
      },
      //(2> 9> 1> 0> 3> 11> 4> 8> 10> 5> 7> 6)
      // 10 9  8  6  4  2   0  -2 -4 -6 -8 -10
    ],
  },
];
